import React from 'react';
import { isArrayLength } from '../../util/genericHelpers';

import css from './ListingPage.module.css';

const SectionTextMaybe = props => {
  const { title, publicData, listingConfig } = props;

  const categoryKey = publicData?.category;

  const categoryOptions =
    isArrayLength(listingConfig?.listingFields) &&
    listingConfig?.listingFields?.find(l => l.key === 'category')?.enumOptions;

  const category =
    categoryKey &&
    isArrayLength(categoryOptions) &&
    categoryOptions?.find(c => c?.option === categoryKey);

  return (
    <div className={css.sectionText}>
      <div className={css.listingHead}>
        <div className={css.leftInfo}>
          {title}
          <div className={css.otherinfo}>
            <span>Category : {category?.label}</span>
            <span>Nearest body of water : {publicData?.nearestBodyOfWater}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTextMaybe;
