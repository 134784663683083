import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews, IconBackArrow, IconRightArrow } from '../../components';

import css from './ListingPage.module.css';
import { isArrayLength } from '../../util/genericHelpers';
import Slider from 'react-slick';
function RenderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconRightArrow className={css.icon} />
    </div>
  );
}

function RenderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconBackArrow className={css.icon} />
    </div>
  );
}
function mergeReviews(reviews, imageReviews) {
  return reviews.map(review => {
    // Find all matching imageReviews based on content and rating
    const matchingImageReviews = isArrayLength(imageReviews)
      ? imageReviews.filter(
          imageReview =>
            imageReview?.content === review?.attributes?.content &&
            imageReview?.rating === review?.attributes?.rating
        )
      : [];

    // If matches are found, combine the images with the review object
    if (matchingImageReviews?.length) {
      // Extract all images from the matching imageReviews
      const images = isArrayLength(matchingImageReviews)
        ? matchingImageReviews.map(imageReview => imageReview.image)
        : [];

      // Concatenate all found images to the review object
      return {
        ...review,
        images: review.images ? review.images.concat(images) : images,
      };
    }

    // If no match is found, return the review without modification
    return review;
  });
}
const sliderSettings = {
  dots: false,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 2000,
  speed: 500,
  slidesToShow: 2, // Display 4 items on desktop
  slidesToScroll: 1, // Scroll 1 item at a time
  variableWidth: false,
  focusOnSelect: false,
  centerMode: false,
  nextArrow: <RenderNextArrow />,
  prevArrow: <RenderPrevArrow />,
  responsive: [
    {
      breakpoint: 1024, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 2,
        variableWidth: false,
      },
    },
    {
      breakpoint: 768, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 2,
        variableWidth: false,
      },
    },
    {
      breakpoint: 480, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 1,
        variableWidth: false,
      },
    },
  ],
};
const SectionReviews = props => {
  const { reviews, imageReviews, fetchReviewsError, onManageDisableScrolling } = props;

  // Now call mergeReviews function with your arrays to get the merged array
  const mergedReviews = mergeReviews(reviews, imageReviews);

  return (
    <section className={css.sectionReviews}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: reviews.length }} />
      </Heading>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <Reviews
        onManageDisableScrolling={onManageDisableScrolling}
        reviews={isArrayLength(mergedReviews) ? mergedReviews : reviews}
      />
    </section>
  );
};

export default SectionReviews;
